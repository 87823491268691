import { template as template_22229d5fd0dd498d8dda78695a452e54 } from "@ember/template-compiler";
const WelcomeHeader = template_22229d5fd0dd498d8dda78695a452e54(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
