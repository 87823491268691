import { template as template_b2c035d4f3f046b89dee8dc6e39cb24e } from "@ember/template-compiler";
const FKControlMenuContainer = template_b2c035d4f3f046b89dee8dc6e39cb24e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
